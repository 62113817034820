import React from "react";
import { useOverrides, Override } from "@quarkly/components";
import { Link, Box, Text, Em, Span, Section } from "@quarkly/widgets";
const defaultProps = {
	"md-padding": "30px 0 30px 0",
	"border-style": "solid",
	"border-color": "rgba(229, 255, 140, 0.62)",
	"border-width": ".1px 0 0 0",
	"padding": "80px 0 80px 0"
};
const overrides = {
	"box": {
		"kind": "Box",
		"props": {
			"empty-border-style": "solid",
			"empty-border-color": "LightGray",
			"width": "60%",
			"md-width": "100%",
			"empty-min-width": "64px",
			"empty-min-height": "64px",
			"empty-border-width": "1px"
		}
	},
	"box1": {
		"kind": "Box",
		"props": {
			"margin": "28px 0px 52px 0px",
			"display": "flex",
			"lg-margin": "24px 0px 16px 0px"
		}
	},
	"link": {
		"kind": "Link",
		"props": {
			"transition": "color 0.3s --transitionTimingFunction-easeInOut 0s",
			"border-style": "solid",
			"border-color": "rgba(255, 255, 255, 0)",
			"href": "/index",
			"text-decoration-line": "initial",
			"margin": "0px 32px 0px 0px",
			"hover-color": "--green",
			"lg-margin": "0px 16px 0px 0px",
			"color": "--light",
			"padding": "6px 0px 6px 0px",
			"hover-border-color": "--color-green",
			"border-width": "1px 0px 0px 0px",
			"lg-font": "normal 400 16px/1.5 \"Varta\", sans-serif",
			"children": "Головна",
			"font": "--headline3"
		}
	},
	"link1": {
		"kind": "Link",
		"props": {
			"hover-border-color": "--color-green",
			"border-style": "solid",
			"href": "/poslugi",
			"padding": "6px 0px 6px 0px",
			"transition": "color 0.3s --transitionTimingFunction-easeInOut 0s",
			"border-width": "1px 0px 0px 0px",
			"lg-font": "normal 400 16px/1.5 \"Varta\", sans-serif",
			"hover-color": "--green",
			"border-color": "rgba(255, 255, 255, 0)",
			"lg-margin": "0px 16px 0px 0px",
			"text-decoration-line": "initial",
			"color": "--light",
			"margin": "0px 32px 0px 0px",
			"children": "Послуги",
			"font": "--headline3"
		}
	},
	"link2": {
		"kind": "Link",
		"props": {
			"hover-color": "--green",
			"transition": "color 0.3s --transitionTimingFunction-easeInOut 0s",
			"padding": "6px 0px 6px 0px",
			"hover-border-color": "--color-green",
			"border-color": "rgba(255, 255, 255, 0)",
			"margin": "0px 32px 0px 0px",
			"border-style": "solid",
			"lg-margin": "0px 16px 0px 0px",
			"href": "/kontakti",
			"text-decoration-line": "initial",
			"color": "--light",
			"border-width": "1px 0px 0px 0px",
			"lg-font": "normal 400 16px/1.5 \"Varta\", sans-serif",
			"children": "Контакти",
			"font": "--headline3"
		}
	},
	"link3": {
		"kind": "Link",
		"props": {
			"border-width": "1px 0px 0px 0px",
			"border-color": "rgba(255, 255, 255, 0)",
			"transition": "color 0.3s --transitionTimingFunction-easeInOut 0s",
			"hover-border-color": "--color-green",
			"href": "/faq",
			"text-decoration-line": "initial",
			"color": "--light",
			"hover-color": "--green",
			"padding": "6px 0px 6px 0px",
			"margin": "0px 32px 0px 0px",
			"border-style": "solid",
			"lg-font": "normal 400 16px/1.5 \"Varta\", sans-serif",
			"lg-margin": "0px 16px 0px 0px",
			"children": "FAQ",
			"font": "--headline3"
		}
	},
	"box2": {
		"kind": "Box",
		"props": {
			"margin": "28px 0px 0px 0px",
			"display": "flex",
			"md-margin": "28px 0px 0px 0px"
		}
	},
	"link4": {
		"kind": "Link",
		"props": {
			"href": "/politika-konfidencijnosti",
			"padding": "6px 0px 6px 0px",
			"margin": "0px 32px 0px 0px",
			"border-style": "solid",
			"hover-color": "--green",
			"transition": "color 0.3s --transitionTimingFunction-easeInOut 0s",
			"hover-border-color": "--color-green",
			"border-color": "rgba(255, 255, 255, 0)",
			"text-decoration-line": "initial",
			"border-width": "1px 0px 0px 0px",
			"lg-font": "normal 400 16px/1.5 \"Varta\", sans-serif",
			"lg-margin": "0px 16px 0px 0px",
			"children": "Політика Конфіденційності",
			"color": "--lightD2",
			"font": "--lead"
		}
	},
	"box3": {
		"kind": "Box",
		"props": {
			"empty-min-height": "64px",
			"empty-border-width": "1px",
			"empty-border-style": "solid",
			"empty-border-color": "LightGray",
			"width": "40%",
			"md-width": "100%",
			"md-margin": "24px 0px 0px 0px",
			"empty-min-width": "64px",
			"font": "--headline3"
		}
	},
	"text": {
		"kind": "Text",
		"props": {
			"md-text-align": "left",
			"margin": "0px 0px 26px 0px",
			"color": "--light",
			"font": "--headline4",
			"text-align": "right",
			"lg-font": "700 22px/1.2 --fontFamily-googleAlegreyaSans, sans-serif",
			"children": "Copyright 2024@ Trailblaze24"
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"md-text-align": "left",
			"margin": "0px 0px 26px 0px",
			"color": "--light",
			"font": "--headline4",
			"text-align": "right",
			"lg-font": "700 22px/1.2 --fontFamily-googleAlegreyaSans, sans-serif",
			"children": <Span
				font-weight="normal"
				overflow-wrap="normal"
				word-break="normal"
				white-space="normal"
				text-indent="0"
				text-overflow="clip"
				hyphens="manual"
				user-select="auto"
				pointer-events="auto"
			>
				<Em
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					Телефонуйте за номером
					<br />
					0977183394
					<br />
					<br />
					<br />
					вулиця Юності, 41А, Микитинці, Івано-Франківська область
				</Em>
			</Span>
		}
	}
};

const Footernew = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
		<Box {...override("box")}>
			<Box {...override("box1")}>
				<Link {...override("link")} />
				<Link {...override("link1")} />
				<Link {...override("link2")} />
				<Link {...override("link3")} />
			</Box>
			<Box {...override("box2")}>
				<Link {...override("link4")} />
			</Box>
		</Box>
		<Box {...override("box3")}>
			<Text {...override("text")} />
			<Text {...override("text1")} />
		</Box>
		{children}
	</Section>;
};

Object.assign(Footernew, { ...Section,
	defaultProps,
	overrides
});
export default Footernew;