import React from "react";
import { useOverrides, Override } from "@quarkly/components";
import { Text, LinkBox, Link, Box, Section } from "@quarkly/widgets";
const defaultProps = {
	"quarkly-title": "Header",
	"padding": "44px 0 44px 0",
	"border-style": "solid",
	"border-color": "rgba(229, 255, 140, 0.66)",
	"border-width": "0 0 .1px 0"
};
const overrides = {
	"linkBox": {
		"kind": "LinkBox",
		"props": {
			"align-items": "flex-start"
		}
	},
	"text": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"color": "--light",
			"font": "--headline3",
			"children": "Trailblaze24"
		}
	},
	"box": {
		"kind": "Box",
		"props": {
			"md-justify-content": "center",
			"md-padding": "30px 0px 30px 0px",
			"display": "flex",
			"justify-content": "flex-end",
			"md-align-items": "center",
			"md-flex-direction": "column"
		}
	},
	"link": {
		"kind": "Link",
		"props": {
			"border-style": "solid",
			"text-decoration-line": "initial",
			"padding": "6px 0px 6px 0px",
			"hover-color": "--green",
			"lg-margin": "0px 16px 0px 0px",
			"color": "--light",
			"hover-border-color": "--color-green",
			"border-width": "1px 0px 0px 0px",
			"href": "/index",
			"transition": "color 0.3s --transitionTimingFunction-easeInOut 0s",
			"border-color": "rgba(255, 255, 255, 0)",
			"font": "--base",
			"margin": "0px 16px 0px 16px",
			"md-margin": "0px 0px 7px 0px",
			"children": <>
				{" "}Головна
			</>
		}
	},
	"link1": {
		"kind": "Link",
		"props": {
			"transition": "color 0.3s --transitionTimingFunction-easeInOut 0s",
			"href": "/poslugi",
			"padding": "6px 0px 6px 0px",
			"margin": "0px 16px 0px 16px",
			"text-decoration-line": "initial",
			"hover-border-color": "--color-green",
			"border-style": "solid",
			"color": "--light",
			"border-width": "1px 0px 0px 0px",
			"lg-margin": "0px 16px 0px 0px",
			"md-margin": "0px 0px 7px 0px",
			"font": "--base",
			"hover-color": "--green",
			"border-color": "rgba(255, 255, 255, 0)",
			"children": "Послуги"
		}
	},
	"link2": {
		"kind": "Link",
		"props": {
			"padding": "6px 0px 6px 0px",
			"margin": "0px 0px 0px 16px",
			"hover-color": "--green",
			"transition": "color 0.3s --transitionTimingFunction-easeInOut 0s",
			"hover-border-color": "--color-green",
			"border-width": "1px 0px 0px 0px",
			"href": "/kontakti",
			"color": "--light",
			"border-color": "rgba(255, 255, 255, 0)",
			"border-style": "solid",
			"lg-margin": "0px 0px 0px 0px",
			"text-decoration-line": "initial",
			"font": "--base",
			"children": "Контакти"
		}
	},
	"link3": {
		"kind": "Link",
		"props": {
			"padding": "6px 0px 6px 0px",
			"margin": "0px 0px 0px 16px",
			"hover-color": "--green",
			"transition": "color 0.3s --transitionTimingFunction-easeInOut 0s",
			"hover-border-color": "--color-green",
			"border-width": "1px 0px 0px 0px",
			"href": "/faq",
			"color": "--light",
			"border-color": "rgba(255, 255, 255, 0)",
			"border-style": "solid",
			"lg-margin": "0px 0px 0px 0px",
			"text-decoration-line": "initial",
			"font": "--base",
			"children": "FAQ"
		}
	}
};

const Header = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Override slot="SectionContent" flex-direction="row" justify-content="space-between" />
		<LinkBox {...override("linkBox")}>
			<Text {...override("text")} />
		</LinkBox>
		<Box {...override("box")}>
			<Link {...override("link")} />
			<Link {...override("link1")} />
			<Link {...override("link2")} />
			<Link {...override("link3")} />
		</Box>
		{children}
	</Section>;
};

Object.assign(Header, { ...Section,
	defaultProps,
	overrides
});
export default Header;